html{
  font-size: 12px;
}
body{
  margin-bottom: 60px !important;
					
}
.login_background{
  background: url('./background_images/bg003.jpg') no-repeat center center fixed;
  overflow:hidden;
}
.btn-myprimary {
  background-color: rgb(0,181,226);
  color:white;
}
.btn-outline-myprimary {
  background: #fff;
  border:1px solid rgb(0,181,226);
  color:rgb(0,181,226);
}

.notranslate:has(.login_background) {
  overflow:hidden;
}
.custom_nav_link{
  align-self: flex-end!important;  
  right:0;
}
.mybox{
  width:20px;
  display:inline-block;
}
.editSection{
  background-color: rgb(150, 214, 193);
}

.titleStickyTop{
  top:76px;
  position:sticky;
  z-index:1010;
  background-color: #fff;
}
.username{
  width: 190px;
  display:block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer{
  position:fixed;
  bottom:0;
  background-color:#1cade5;
  z-index:100;
}
.levelbox{
  width:10px;
  display:inline-block;
  height:25px;
  border:1px solid #fff;
}
.text-subinfo{
  color: #009999;
}
.alert_container{
  position: fixed;
  z-index: 10000;
  opacity: 0.9;
  right: 0;
}
.section_title{
  font-size: 0.7em;
}

.fc-daygrid-event{
  overflow:hidden;
}
.source_list_container{
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
 .custom-modal{
  width: 100VW !important;
  max-width: 95% !important;
  height: 100VH;
 }
 .imageDocumentViewer{
      height: 100%;
   width: auto;
   overflow: auto;
    margin: auto;
}
 .imageDocumentViewerSmall{
   width: 100%;
   overflow: auto;
    margin: auto;
}
.selected_row{
  background-color: lightgrey;
}
@media (min-width: 992px)
.full-modal-lg {
    width: auto;
}

@media (min-width: 768px)
.full-modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
 }
@media (min-width: 768px)
.full-modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    height: auto;
    min-height: 100%;
    border-radius: 0;
}

.mydrop-item:hover .dropdown-menu{
  display:block!important
}

.nav-pills .nav-link {
    border: 1px solid grey;
    color: grey;
}
.nav-pills .nav-link.active {
  color: orangered;
  border: 1 px solid black;
}

.page_title.active {
  background-color: #1cade5;
}
.page_title.inactive {
  background-color: #1cade5;
}
.page_title.activeadmin {
  background-color: orangered
}

.react-datepicker__month-container{
  height:265px;
}

.nav-pills .nav-link:hover{
  color: #000;
  background-color: grey;
}
.one_workarea{
  background-image: linear-gradient(#dcdcdc, white);
}
#tooltip-top > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #062e56;
}
.popover-body {
  background-color:#dcdcdc;
  color: #000;
}
.bs-popover-bottom>.arrow::after {
  border-bottom-color: #dcdcdc !important;
}

.popover {
  max-width: 600px !important;
  min-width:388px;
}
/* CSS NAVBAR */
.margin-right-icon {
  margin-right: 5px !important;
}

.bottom-border-open {
  border-end: 1px solid white;
  border-top: 1px solid white;
  border-start: 1px solid white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.bottom-border-open-select {
  border-end: 1px solid black;
  border-top: 1px solid black;
  border-start: 1px solid black;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.menu-botton {
  border:1px solid !important;
  /*border-radius:20px !important;*/
}
.element_row:hover{
  background-color: #e6e6e6;
}
.element_row_inverted{
  background-color: #e6e6e6;
}
.element_row_inverted:hover{
  background-color: #fff;
}

@media print {
  .element_row_inverted{
background-color: #fff;
  }
  .badge{
		print-color-adjust:exact;
  }
  .header_row {
		print-color-adjust:exact;
		background-color: #ffaabb !important;
	  }
}


/* breadcrumb */
.breadcrumb {
  display: flex;
  background-color: transparent !important;
  margin:0px !important;
}

.breadcrumb-arrow {
  min-height: 36px;
  padding: 0 !important; 
  line-height: 36px;
  list-style: none;
  overflow: auto;
  /*    background-color: #e6e9ed*/
  /*background: linear-gradient(to right, #eaeaea 0%,#ffffff 100%);*/
}

.breadcrumb-arrow li:first-child a {
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
}

.breadcrumb-arrow li,
.breadcrumb-arrow li a,
.breadcrumb-arrow li span {
  display: inline-block;
  vertical-align: top;
}


.breadcrumb-arrow li+li:before {
  padding: 0;
  content: "";
}

.breadcrumb-arrow li span {
  padding: 0 10px;
}

.breadcrumb-arrow li a,
.breadcrumb-arrow li:not(:first-child) span {
  height: 36px;
  padding: 0 5px 0 12px;
  line-height: 36px;
}

.breadcrumb-arrow li:first-child a {
  padding: 0 5px;
}

.breadcrumb-arrow li a {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #7a7c7e;
  border: 1px solid #7a7c7e;
}

.breadcrumb-arrow li:first-child a {
  padding-left: 4px;
}

.breadcrumb-arrow li a:after,
.breadcrumb-arrow li a:before {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  content: '';
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.breadcrumb-arrow li a:before {
  right: -4px;
  z-index: 3;
  border-start-color: #7a7c7e;
  border-start-style: solid;
  border-start-width: 4px;
}

.breadcrumb-arrow li a:after {
  right: -5px;
  z-index: 2;
  border-start: 5px solid #fff;
}

.breadcrumb-arrow li a:focus,
.breadcrumb-arrow li a:hover {
  background-color: #7a7c7e;
  border: 1px solid #7a7c7e;
}

.breadcrumb-arrow li a:focus:before,
.breadcrumb-arrow li a:hover:before {
  border-start-color: #7a7c7e;
}

.breadcrumb-arrow li a:active {
  background-color: #7a7c7e;
  border: 1px solid #7a7c7e;
}

.breadcrumb-arrow li a:active:after,
.breadcrumb-arrow li a:active:before {
  border-start-color: #7a7c7e;
}

/*set for Last child*/

.breadcrumb-arrow li.active span {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #1cade5;
  border: 1px solid #0083b5;
}

.breadcrumb-arrow li.activeadmin span {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #e51c1c;
  border: 1px solid #b50000;
}
.breadcrumb-arrow li.button span {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #0083b5;
  border: 1px solid #0083b5;
  font-size: 0.8em;
}
.breadcrumb-arrow li.active:first-child span,
.breadcrumb-arrow li.activeadmin:first-child span   {
  padding-left: 10px;
}

.breadcrumb-arrow li.active span:after,
.breadcrumb-arrow li.active span:before,
.breadcrumb-arrow li.activeadmin span:after,
.breadcrumb-arrow li.activeadmin span:before {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  content: '';
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.breadcrumb-arrow li.active span:before{
  right: -4px;
  z-index: 3;
  border-start-color: #1cade5;
  border-start-style: solid;
  border-start-width: 5px;
}

.breadcrumb-arrow li.active span:after{
  right: -5px;
  z-index: 2;
  border-start: 4px solid #0083b5;
}

.breadcrumb-arrow li.activeadmin span:before {
  right: -4px;
  z-index: 3;
  border-start-color: #e51c1c;
  border-start-style: solid;
  border-start-width: 5px;
}

.breadcrumb-arrow li.activeadmin span:after {
  right: -5px;
  z-index: 2;
  border-start: 4px solid #b50000;
}

.breadcrumb-arrow li.button span:after,
.breadcrumb-arrow li.button span:before {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  content: '';
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.breadcrumb-arrow li.button span:before {
  right: -4px;
  z-index: 3;
  border-start-color: #0083b5;
  border-start-style: solid;
  border-start-width: 5px;
}

.breadcrumb-arrow li.button span:after {
  right: -5px;
  z-index: 2;
  border-start: 4px solid #0083b5;
}

.selected_event{
  border: 2px solid green
}

.bigborder{
  border-width: 3px !important;
  border-radius: 5px;
  cursor:pointer;
}
.fc-slot.hiddenhour{
  display: none;
  color:#fff !important;
}
.fc-scrollgrid-section-sticky{
  position:sticky;
  z-index:950;
}
.fc-h-event{
  border:0px !important;
  padding:0!important;
}
.node-inner{
	border-width:1px;
	text-align:center;
	border-style:solid;
	min-width:150px;
	padding: 3px;

}
.selected >.node-inner {
  border-color: #e2d300 !important;
  border-width:2px;
}
.personalcard{
        width: 90mm;
        height: 60mm;

}
  .qrcard{
        width: 90mm;
        height: 60mm;
  }
  .qr-title{
	height:12mm;
  padding:1mm;
  overflow: hidden;
  }
	  .single_label {
		width: 50mm !important;
		height:50mm !important;
		display: inline-block;
	  }
	.preprint_page {
	  width:210mm !important;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  @media screen{

  .printonly_visible{
    display:none !important;
  }
  }
/* @media print {
  @page { size: landscape;
   }

} */

.animatedBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0000;
  border-radius: 12px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(
        var(--angle),
        #fff,
        #1cade5
      ) border-box;
  animation: 8s rotate linear infinite;
}


.animatedBox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 3px solid #fff;
  border-radius: 12px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(
        var(--angle),
        #aaa,
        #1cade5
      ) border-box;
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.font-italic{
  font-style: italic;
}